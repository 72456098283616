<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" collapseHeight="120">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="用户职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="positionList" :props="props"
            placeholder="请选择用户职务">
          </el-cascader>
        </el-form-item>
        <el-form-item label="本月签到：" prop="">
          <div class="container">
            <el-form-item label-width="0px" prop="start_month">
              <el-input v-model.number="start_month"></el-input>
            </el-form-item>
            <div style="margin: 0 10px">-</div>
            <el-form-item label-width="0px" prop="end_month">
              <el-input v-model.number="end_month"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="总签到：" prop="">
          <div class="container">
            <el-form-item label-width="0px" prop="start_month">
              <el-input v-model.number="start_total"></el-input>
            </el-form-item>
            <div style="margin: 0 10px">-</div>
            <el-form-item label-width="0px" prop="end_month">
              <el-input v-model.number="end_total"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import { positionLevel } from "../../api/sign-in-list";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      rules: {},
      positionList: [],
    };
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.positionList = res.data;
        })
        .catch(() => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getPositionLevel();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    start_total: {
      get() {
        return this.form.start_total == -1 ? "" : this.form.start_total;
      },
      set(val) {
        this.form.start_total = val;
      },
    },
    end_total: {
      get() {
        return this.form.end_total == -1 ? "" : this.form.end_total;
      },
      set(val) {
        this.form.end_total = val;
      },
    },
    start_month: {
      get() {
        return this.form.start_month == -1 ? "" : this.form.start_month;
      },
      set(val) {
        this.form.start_month = val;
      },
    },
    end_month: {
      get() {
        return this.form.end_month == -1 ? "" : this.form.end_month;
      },
      set(val) {
        this.form.end_month = val;
      },
    },
  },
  components: {
    LayoutFilter,
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  ::v-deep.el-input__inner {
    width: 67px !important;
  }
}
</style>