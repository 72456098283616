import api from "@/base/utils/request";

// 获取今日签到列表数据
export const signTodayList = data => {
  return api({
    url: "/admin/incomes/user_sign/index",
    method: "post",
    data
  });
};

// 获取本月签到列表
export const signMonthList = data => {
  return api({
    url: "/admin/incomes/user_sign/month",
    method: "post",
    data
  });
};

//获取总签到列表
export const signTotalList = data => {
  return api({
    url: "/admin/incomes/user_sign/total",
    method: "post",
    data
  });
};

// 获取职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};