<template>
  <div class="list-page">
    <sign-month-filter :filterForm="filterForm" :uploadFilter="ok" />
    <div class="list-content" v-loading="tableLoading">
      <el-table :data="tbody" style="width: 100%" class="thead-light" stripe @sort-change="sortChange">
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :label="th.label"
          :prop="th.prop" :width="th.width|| ''" :min-width="th.minWidth || ''" :sortable="th.sortable">
          <template slot-scope="{row}">
            <!-- 头像 -->
            <el-image class="list-icon" v-if="th.prop === 'avatar'" :src="fixImageUrl(row[th.prop])"
              @click="$previewImage([row[th.prop]])" fit="cover"></el-image>
            <!-- 其他 -->
            <span v-else>{{row[th.prop] | placeholder}}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
  </div>
</template>
<script>
import { signMonthList } from "../../api/sign-in-list";
import Pagination from "@/base/components/Default/Pagination";
import SignMonthFilter from "../../components/SignIn/SignMonthFilter.vue";
export default {
  data() {
    return {
      tableLoading: false, //表格加载中
      thead: [
        { label: "用户ID", prop: "user_id", minWidth: 80 },
        { label: "头像", prop: "avatar", minWidth: 60 },
        { label: "用户名", prop: "nickname", minWidth: 110 },
        { label: "职务", prop: "title_name", minWidth: 120 },
        {
          label: "本月签到数",
          prop: "month_signs_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "总签到数",
          prop: "total_signs_count",
          minWidth: 120,
          sortable: true,
        },
      ],
      tbody: [],
      filterForm: {
        page_size: 15,
        keyword: "",
        end_time: -1,
        start_total: -1,
        end_total: -1,
        start_month: -1,
        end_month: -1,
        positionIds: [],
      },
      pageData: {},
    };
  },
  methods: {
    //获取数据列表
    getSignMonthList(requestData) {
      this.tableLoading = true;
      this.pageData = {};
      signMonthList(requestData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getSignMonthList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getSignMonthList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getSignMonthList(this.filterForm);
    },
  },
  created() {
    //获取数据列表
    this.getSignMonthList(this.filterForm);
  },
  components: {
    Pagination,
    SignMonthFilter,
  },
};
</script>

<style lang="scss" scoped>
.list-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 50%;
}

.list-content {
  margin-top: 20px;
}
</style>
